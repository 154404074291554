import React, {useEffect, useState, useContext} from "react"
import {Link, navigate} from "gatsby";
import {FiShoppingCart} from 'react-icons/fi';
import {useIsMobile} from "../utils";
import { CartContext } from '../cart';
import { HamburgerButton } from "./sidebar";

const Item = ({title, link, active}) => {
    const isActive = window.location.pathname.includes(link);
    return (
        <div
        >
            <Link
              className="head-link"
        to={link} style={{
              ...(isActive && {color: '#D8D8D8', textDecoration: 'underline'})
            }}>{title}</Link>
        </div>
    )
}

const Navigation = () => {
    const { screenWidth } = useIsMobile();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                flexBasis: 0,
            }}
        >
            {
                screenWidth < 1160? [
                  <HamburgerButton 
                  />
                ] : [
                    <Item title="ABOUT" link="/about" active={true} key="navi-about"/>,
                    <Item title="GUIDES" link="/guides" active={true} key="navi-guides"/>,
                    <Item title="SHOP" link="/shop" active={true} key="navi-shop"/>,
                    <Item title="SUBSCRIBE" link="/subscribe" active={true} key="navi-subscribe"/>,
                    <Item title="WHOLESALE" link="/wholesale" active={true} key="navi-wholesale"/>,
                    <Item title="FAQ" link="/faq" active={true} key="navi-faq"/>
                ]
            }
        </div>
    );
}

const HeadMenu = () => {
    const { itemCount } = useContext(CartContext);
    return (
        <div
            style={{
                color: '#ffffff',
                background: '#000000',
                display: "flex",
                alignItems: "center",
                padding: "16px 48px",
                position: 'sticky',
                top: 0,
                right: 0,
                left: 0,
                zIndex: 100,
            }}
        >
            <Navigation/>
            <div style={{
                flexBasis: 0,
                cursor: 'pointer',
                flexGrow: 0.5, alignItems: 'center', justifyContent:　'center', display: 'flex'}}>
                <img src={"/logo.png"} style={{width: 80}} onClick={() => {
                    navigate('/');
                }}/>
            </div>
            <div style={{
                flexBasis: 0,
                position: 'relative',
                display: 'flex', flexGrow: 1, height: 20, justifyContent: 'end'}}>
                <div onClick={() => {
                    navigate('/cart');
                }}
                      style={{ color: '#ffffff', textDecoration: 'none', cursor: 'pointer' }}>
                    <FiShoppingCart size={24}/>
                <div style={{
            position: 'absolute',
            background: '#4d4d4d',
            textAlign: 'center',
            borderRadius: '50%',
            width: 18,
            height: 18,
            top: -10,
            right: -20,
            fontSize: 12,
          }}>{itemCount}</div>
                </div>
            </div>
        </div>
    )
}

export default HeadMenu;
